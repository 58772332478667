import React, { useEffect, useState } from "react";
import { Modal, Button, Card, Row, Col, Typography } from "antd";
import QrReader from "react-qr-barcode-scanner";
import { NotificationManager } from "react-notifications";
const { Text } = Typography;

//// QR code modal code
const QRCodeScanWithDetailsModal = ({ visible, onSubmitData, onCancel }) => {
  const [visitorData, setVisitorData] = useState({});
  const [isVisitorDataShow, setIsVisitorDataShow] = useState(false);
  const [cameraError, setCameraError] = useState(null);
  const [scanActive, setScanActive] = useState(false);
  const [visitorId, setVisitorId] = useState(0);

  useEffect(() => {
    if (visible) {
      setVisitorData({});
      setIsVisitorDataShow(false);
      const checkCameraAccess = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          stream.getTracks().forEach((track) => track.stop());
          setCameraError(null);
          setScanActive(true);
        } catch (error) {
          setCameraError("Camera access denied or not available.");
        }
      };
      checkCameraAccess();
    } else {
      setScanActive(false);
    }
  }, [visible]);

  const handleScan = (result) => {
    const parsedResultData = JSON.parse(result.text.trim());
    const resultDate = new Date(parsedResultData.VisitingDate);
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);
    resultDate.setHours(0, 0, 0, 0);

    if (result) {
      if (resultDate.getTime() === currentDate.getTime()) {
        const formattedData = {
          visitorName: parsedResultData.VisitorName,
          contactNumber: parsedResultData.ContactNo,
          visitingDate: parsedResultData.VisitingDate,
          belonging: parsedResultData.Belonging,
          purpose: parsedResultData.Purpose,
          visitorType: parsedResultData.VisitType,
          companyName: parsedResultData.CompanyName,
        };
        setVisitorData(formattedData);
        setVisitorId(parsedResultData.VisitorId);
        setIsVisitorDataShow(true);
      } else if (resultDate.getTime() > currentDate.getTime()) {
        NotificationManager.error("This is Future QR code");
        setIsVisitorDataShow(false);
        setScanActive(false);
        setIsVisitorDataShow(false);
        onCancel();
      } else {
        NotificationManager.error("This was an invalid QR code");
        setIsVisitorDataShow(false);
        setScanActive(false);
        setIsVisitorDataShow(false);
        onCancel();
      }
    }
  };
  const handleOnSubmitData = () => {
    onSubmitData(visitorId);
  };
  const handleError = (err) => {
    console.error("QR Scanner Error:", err);
  };

  return (
    <Modal
      className="delete_modal"
      title="User Details"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel} className="btn_cancel_mdl">
          Cancel
        </Button>,
        <Button onClick={handleOnSubmitData} className="btn_delete_mdl">
          Submit
        </Button>,
      ]}
    >
      <div>
        {!isVisitorDataShow && scanActive && (
          <QrReader
            delay={false}
            onUpdate={(err, result) => {
              if (result) handleScan(result);
              if (err) handleError(err);
            }}
            constraints={{ facingMode: "environment" }}
            style={{ width: "100%", height: "200px" }}
          />
        )}

        {isVisitorDataShow && (
          <div style={{ padding: "10px", width: "100%", margin: "0 auto" }}>
            <Card
              style={{
                background: "#fff",
                borderRadius: "8px",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              }}
            >
              <Row gutter={[10, 4]}>
                <Col xs={24} sm={12}>
                  <Text strong>Visitor Name:</Text> {visitorData.visitorName}
                </Col>
                <Col xs={24} sm={12}>
                  <Text strong>Company Name:</Text> {visitorData.companyName}
                </Col>
              </Row>

              <Row gutter={[10, 4]}>
                <Col xs={24} sm={12}>
                  <Text strong>Contact Number:</Text>
                  {" " + visitorData.contactNumber}
                </Col>
                <Col xs={24} sm={12}>
                  <Text strong>Belonging:</Text> {visitorData.belonging}
                </Col>
              </Row>

              <Row gutter={[10, 4]}>
                <Col xs={24} sm={12}>
                  <Text strong>Purpose:</Text> {visitorData.purpose}
                </Col>
                <Col xs={24} sm={12}>
                  <Text strong>Visitor Type:</Text> {visitorData.visitorType}
                </Col>
              </Row>

              <Row gutter={[10, 4]}>
                <Col xs={24}>
                  <Text strong>Visiting Date:</Text>
                  {" " + new Date(visitorData.visitingDate).toLocaleString()}
                </Col>
              </Row>
            </Card>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default QRCodeScanWithDetailsModal;
