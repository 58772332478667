import {
  httpTokenCommon,
  httpTokenFileDownload,
} from "../../helper/http-common";

class VisitorManagementService {
  getCompanyListAPI() {
    return httpTokenCommon.post("VisitorsManagment/CompanyList");
  }
  getEmployeeNameListAPI() {
    return httpTokenCommon.get("GatePass/GetEmpNameList");
  }
  postUpdateTime(inputData) {
    return httpTokenCommon.post("GatePass/UpdateTime", inputData);
  }
  getCreateGatePassTableDataAPI(inputData) {
    return httpTokenCommon.post("GatePass/GetGatePassData", inputData);
  }
  postCreateGatePassAPI(inputData) {
    return httpTokenCommon.post("GatePass/AddUpdateGatePass", inputData);
  }
  deleteGatePassAPI(id) {
    return httpTokenCommon.post(`GatePass/DeleteGatePass?Id=${id}`);
  }
  getAssignVehiclesListAPI() {
    return httpTokenCommon.get("GatePass/GetVehicleList");
  }

  postReceptionReportExportPDF(input) {
    return httpTokenFileDownload.post("GatePass/Export", input, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  //Visitor Management
  getVisitorManagementTableDataAPI(inputData) {
    return httpTokenCommon.post("VisitorsManagment/GetVisiterList", inputData);
  }

  deleteVisitorAPI(id) {
    return httpTokenCommon.post(`VisitorsManagment/DeleteVisitor?id=${id}`);
  }
  postCreateUpdateVisitorsAPI(inputData) {
    return httpTokenCommon.post(
      "VisitorsManagment/AddUpdateVisitor",
      inputData
    );
  }

  getBranchListAPI() {
    return httpTokenCommon.post("VisitorsManagment/BranchList");
  }
  getVisitorTypeListAPI() {
    return httpTokenCommon.post("VisitorsManagment/VisitorTypeList");
  }
  exportVisitorListAPI(input) {
    return httpTokenFileDownload.post("VisitorsManagment/Export", input, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  printVisitorCardAPI(id) {
    return httpTokenFileDownload.post(
      `VisitorsManagment/ExportToPdf?id=${id}`,
      {
        headers: {
          "Content-Type": "application/pdf",
        },
      }
    );
  }

  UpdateVisitorInOutTimeAPI(inputData) {
    return httpTokenCommon.put(
      "VisitorsManagment/UpdateVisitorInOutTime",
      inputData
    );
  }
}

const visitorManagementService = new VisitorManagementService();
export default visitorManagementService;
