import React, { Fragment, useState, useEffect, useRef } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import formatNumber from "../../../../utils/formatNumber";

const InvoiceDetailsTbl = ({
  invoiceDetailsData,
  isLoading,
  OnhandleTableSortColumn,
  selectAnalysisType,
  selectedDistributionIds,
  selectedCountryIds,
  selectedPriceGroupIds,
  selectedSalesOfficeIds,
  isEmployee,
}) => {
  const isFirstRender = useRef(true);
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );
  const globalFilters = useSelector(
    (state) => state.GlobalFilters.globalFilterSapCodes
  );

  const selectedCustomerData = useSelector(
    (state) => state.PaymentTableFilter.selectedCustomerData
  );
  const prevSelectedCustomerData = useRef(selectedCustomerData);
  const tableContainerRef = useRef(null);
  const [invoiceTableData, setInvoiceTableData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pastPageNo, setPastPageNo] = useState(1);
  const [selectedCompany, setSelectedCompany] = useState(
    companyDetails?.company_Code || ""
  );
  const [checkSelectAnalysisType, setCheckSelectAnalysisType] =
    useState(selectAnalysisType);

  const finalInvoiceDataRef = useRef([]);
  const totalRecordsRef = useRef(0);
  const checkPaginationNoDataRef = useRef(0);

  useEffect(() => {
    if (invoiceDetailsData?.customerInvoiceList?.length > 0) {
      const finalData = invoiceDetailsData?.customerInvoiceList || [];
      finalInvoiceDataRef.current = invoiceDetailsData;

      finalData.forEach((el) => {
        let subTableData = el.customerAgeingList;
        subTableData.forEach((subData) => {
          const formattedDate = subData.invoiceDate.replace(/\//g, "");
          const currentSeconds = new Date()
            .getMinutes()
            .toString()
            .padStart(2, "0");
          const invoiceId = `${formattedDate}${subData.invoiceNo}${currentSeconds}`;
          subData.invoiceId = invoiceId;
        });
      });
      if (selectedCustomerData?.length > 0) {
        prevSelectedCustomerData.current = selectedCustomerData;
      }

      const existingKeys = new Set(
        invoiceTableData.map((item) => item.invoiceId)
      );
      const existingInvoiceNos = new Set();
      let CompanyCode = localStorage.getItem("Company_Code");
      let selectedAnalysisType = localStorage.getItem("selectedAnalysisType");
      let transformedData;
      const isSelectedCustomerDataEmpty =
        selectedCustomerData?.length === 0 &&
        prevSelectedCustomerData.current?.length !== 0;
      if (
        selectedCompany !== CompanyCode ||
        (globalFilters && Object.keys(globalFilters)?.length > 0) ||
        checkSelectAnalysisType !== selectedAnalysisType ||
        isSelectedCustomerDataEmpty
      ) {
        if (pastPageNo === Number(invoiceDetailsData.currentPage)) {
          setSelectedCompany(CompanyCode);
          setCheckSelectAnalysisType(selectAnalysisType);
          transformedData = [];
          setPageNo(1);
          setPastPageNo(Number(invoiceDetailsData.currentPage));
        } else if (pageNo > pastPageNo) {
          transformedData = [...invoiceTableData];
        }

        prevSelectedCustomerData.current = "";
      } else if (
        selectedDistributionIds.length > 0 ||
        selectedCountryIds.length > 0 ||
        selectedPriceGroupIds.length > 0 ||
        selectedSalesOfficeIds.length > 0
      ) {
        if (pastPageNo === Number(invoiceDetailsData.currentPage)) {
          transformedData = [];
          setPageNo(1);
          setPastPageNo(Number(invoiceDetailsData.currentPage));
        } else if (pageNo > pastPageNo) {
          transformedData = [...invoiceTableData];
        }
      } else if (pastPageNo === Number(invoiceDetailsData.currentPage)) {
        transformedData = [];
        setPageNo(1);
        setPastPageNo(Number(invoiceDetailsData.currentPage));
      } else {
        transformedData =
          selectedCustomerData?.length > 0 ? [] : [...invoiceTableData];
      }

      finalData.forEach((customerData) => {
        const totalInvoices = customerData.customerAgeingList.length;
        const existingcustomerOrganizationData = transformedData.findIndex(
          (item) => item.key === customerData.customerOrganization
        );
        // const existingIndex = transformedData.findIndex(
        //   (item) => item.key === `${customerData.customerOrganization}-total`
        // );
        // if (existingIndex !== -1) {
        //   transformedData.splice(existingIndex, 1);
        // }
        if (existingcustomerOrganizationData !== -1) {
          customerData.customerAgeingList.forEach((invoice, index) => {
            /// check for the Duplicate customerOrganization
            if (
              existingKeys.has(invoice.invoiceId) ||
              existingInvoiceNos.has(invoice.invoiceNo)
            ) {
              return;
            }
            transformedData.push({
              key: customerData.customerOrganization,
              customerOrganization: null, // Show customer name only for the first row
              rowSpan: 0, // Set rowSpan for the first row
              invoiceDate: invoice.invoiceDate || "N/A",
              invoiceNo: invoice.invoiceNo,
              totalPendingAmount_LCY: invoice.totalPendingAmount_LCY,
              totalInvoiceAmt: invoice.totalInvoiceAmt,
              totalundamt: invoice.totalundamt,
              totalunadjamt: invoice.totalunadjamt,
              totalPendingAmount: invoice.totalPendingAmount,
              totalcredit: invoice.totalcredit,
              totalcreditdays: invoice.totalcreditdays,
              totaloverdued: invoice.totaloverdued,
              totalTodaytot: invoice.totalTodaytot,
              totalfirst: invoice.totalfirst,
              totalsecond: invoice.totalsecond,
              totalthird: invoice.totalthird,
              totalfourth: invoice.totalfourth,
              totalfifth: invoice.totalfifth,
              totalsixth: invoice.totalsixth,
              totalseventh: invoice.totalseventh,
              totaleighth: invoice.totaleighth,
              currency: invoice.currency,
              duedt: invoice.duedt,
            });
            existingKeys.add(invoice.invoiceId);
            existingInvoiceNos.add(invoice.invoiceNo);
          });
        } else {
          customerData.customerAgeingList.forEach((invoice, index) => {
            if (
              existingKeys.has(invoice.invoiceId) ||
              existingInvoiceNos.has(invoice.invoiceNo)
            ) {
              return;
            }

            transformedData.push({
              key: customerData.customerOrganization,
              customerOrganization:
                index === 0 ? customerData.customerOrganization : null, // Show customer name only for the first row
              rowSpan: index === 0 ? totalInvoices + 1 : 0, // Set rowSpan for the first row
              invoiceDate: invoice.invoiceDate || "N/A",
              invoiceNo: invoice.invoiceNo,
              totalPendingAmount_LCY: invoice.totalPendingAmount_LCY,
              totalInvoiceAmt: invoice.totalInvoiceAmt,
              totalundamt: invoice.totalundamt,
              totalunadjamt: invoice.totalunadjamt,
              totalPendingAmount: invoice.totalPendingAmount,
              totalcredit: invoice.totalcredit,
              totalcreditdays: invoice.totalcreditdays,
              totaloverdued: invoice.totaloverdued,
              totalTodaytot: invoice.totalTodaytot,
              totalfirst: invoice.totalfirst,
              totalsecond: invoice.totalsecond,
              totalthird: invoice.totalthird,
              totalfourth: invoice.totalfourth,
              totalfifth: invoice.totalfifth,
              totalsixth: invoice.totalsixth,
              totalseventh: invoice.totalseventh,
              totaleighth: invoice.totaleighth,
              currency: invoice.currency,
              duedt: invoice.duedt,
            });
            existingKeys.add(invoice.invoiceId);
            existingInvoiceNos.add(invoice.invoiceNo);
          });
        }

        transformedData.push({
          key: `${customerData.customerOrganization}-total`,
          customerOrganization: null,
          rowSpan: 0,
          invoiceDate: "Total",
          invoiceNo: null,
          totalPendingAmount_LCY: customerData.totalPendingAmount_LCY,
          totalInvoiceAmt: customerData.totalInvoiceAmt,
          totalundamt: customerData.totalundamt,
          totalunadjamt: customerData.totalunadjamt,
          totalPendingAmount: customerData.totalPendingAmount,
          totalcredit: customerData.totalcredit,
          totalcreditdays: customerData.totalcreditdays,
          totaloverdued: customerData.totaloverdued,
          totalTodaytot: customerData.totaltodaytot,
          totalfirst: customerData.totalfirst,
          totalsecond: customerData.totalsecond,
          totalthird: customerData.totalthird,
          totalfourth: customerData.totalfourth,
          totalfifth: customerData.totalfifth,
          totalsixth: customerData.totalsixth,
          totalseventh: customerData.totalseventh,
          totaleighth: customerData.totaleighth,
        });
        existingKeys.add(customerData.customerOrganization);
        return transformedData;
      });

      const finalTotalRecords = finalData.reduce(
        (acc, item) => acc + item.customerAgeingList.length,
        0
      );
      const filteredTotalData = filterLastTotalEntries(transformedData);

      setInvoiceTableData(filteredTotalData);
      totalRecordsRef.current = Number(invoiceDetailsData.totalRecords);
      if (Number(invoiceDetailsData.currentPage) > 1) {
        if (!isFirstRender.current) {
          checkPaginationNoDataRef.current += Number(finalTotalRecords);
          isFirstRender.current = true;
        }
      } else {
        if (checkSelectAnalysisType !== selectedAnalysisType) {
          checkPaginationNoDataRef.current = Number(finalTotalRecords);
          setPageNo(1);
        } else {
          if (pageNo === Number(invoiceDetailsData.currentPage)) {
            checkPaginationNoDataRef.current = Number(finalTotalRecords);
          } else {
            checkPaginationNoDataRef.current = Number(finalTotalRecords);
          }
        }
      }

      setTimeout(() => {
        isFirstRender.current = true;
      }, 1000);
    } else {
      setInvoiceTableData([]);
      finalInvoiceDataRef.current = [];
    }
  }, [invoiceDetailsData]);

  useEffect(() => {
    if (pageNo !== 1) {
      OnhandleTableSortColumn({
        currentPage: pageNo,
        table: "Invoice Details",
        sortColumn: "invoiceDate",
        orderBy: "asc",
        isPaginationSorting: false,
      });
    }
  }, [pageNo]);

  useEffect(() => {
    const tableBody =
      tableContainerRef.current?.querySelector(".ant-table-body");

    if (!tableBody) return;

    let prevScrollTop = tableBody.scrollTop || 0;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = tableBody;

      if (scrollTop > prevScrollTop) {
        handleTableOnScrollData(scrollTop, scrollHeight, clientHeight);
      }

      prevScrollTop = scrollTop;
    };

    tableBody.addEventListener("scroll", handleScroll);

    return () => {
      tableBody.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /// Find out the last total Data
  function filterLastTotalEntries(data) {
    const lastTotalIndexMap = new Map();

    // Iterate over data and store the last index of each "key-total"
    data.forEach((item, index) => {
      if (item.key.endsWith("-total")) {
        lastTotalIndexMap.set(item.key, index);
      }
    });

    return data.filter((item, index) => {
      return (
        !item.key.endsWith("-total") ||
        lastTotalIndexMap.get(item.key) === index
      );
    });
  }

  // Scroll event handler for Table
  const handleTableOnScrollData = (scrollTop, scrollHeight, clientHeight) => {
    const isNearBottom = scrollTop + clientHeight >= scrollHeight - 50;

    if (isNearBottom) {
      // if (selectedCustomerData?.length === 0 || selectedCustomerData === null) {
      if (totalRecordsRef.current > checkPaginationNoDataRef.current) {
        if (isFirstRender.current) {
          setPageNo((prevPageNo) => {
            const nextPage = prevPageNo + 1;
            return nextPage;
          });
          isFirstRender.current = false;
        }
      }
      // }
    }
  };
  const columns = [
    {
      title: "Customer",
      dataIndex: "customerOrganization",
      key: "customerOrganization",
      fixed: "left",
      background: "white",
      width: 230,
      position: "sticky",
      render: (text, record) => {
        return {
          children: text ? <strong>{text}</strong> : null,
        };
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 140,
      background: "#f5f5f5",
      fixed: "left",
      render: (text) => (text === "Total" ? <strong>{text}</strong> : text),
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      width: 150,
      fixed: "left",
    },
    {
      title: "Due Date",
      dataIndex: "duedt",
      key: "invoiceNo",
      width: 120,
      fixed: "left",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "invoiceNo",
      width: 100,
      fixed: "left",
    },
    {
      title: "Invoice Amount",
      dataIndex: "totalInvoiceAmt",
      key: "totalInvoiceAmt",
      width: 150,
      fixed: "left",
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalInvoiceAmt || "0").replace(/,/g, ""))
        )}`,
    },
    ...(isEmployee
      ? [
          {
            title: isEmployee ? "Pending Amount LCY" : "Pending Amount",
            dataIndex: "totalPendingAmount_LCY",
            key: "totalPendingAmount_LCY",
            width: 200,
            // sorter: (a, b) =>
            //   parseFloat((a.totalPendingAmount_LCY || "0").replace(/,/g, "")) -
            //   parseFloat((b.totalPendingAmount_LCY || "0").replace(/,/g, "")),
            // showSorterTooltip: false,
            render: (text, record) =>
              `${formatNumber(
                parseFloat(
                  (record.totalPendingAmount_LCY || "0").replace(/,/g, "")
                )
              )}`,
          },
        ]
      : []),
    {
      title: "Undue Amount",
      dataIndex: "totalundamt",
      key: "totalundamt",
      width: 150,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalundamt || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Unadjusted Amount",
      dataIndex: "totalunadjamt",
      key: "totalunadjamt",
      width: 200,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalunadjamt || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Pending Amount",
      dataIndex: "totalPendingAmount",
      key: "totalPendingAmount",
      width: 150,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalPendingAmount || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Total Credit Limit",
      dataIndex: "totalcredit",
      key: "totalcredit",
      width: 200,
      render: (text, record) =>
        `${formatNumber(
          parseFloat((record.totalcredit || "0").replace(/,/g, ""))
        )}`,
    },
    {
      title: "Credit Days",
      dataIndex: "totalcreditdays",
      key: "totalcreditdays",
      width: 150,
      render: (_i, row) => {
        return <div>{formatNumber(row.totalcreditdays)}</div>;
      },
    },
    {
      title: "Over Due Days",
      dataIndex: "totaloverdued",
      key: "totaloverdued",
      width: 180,
      render: (_i, row) => {
        return <div>{formatNumber(row.totaloverdued)}</div>;
      },
    },
    {
      title: "Today Total Days",
      dataIndex: "totalTodaytot",
      key: "totalTodaytot",
      width: 200,
      render: (_i, row) => {
        return <div>{formatNumber(row.totalTodaytot)}</div>;
      },
    },
    {
      title: "0-10 Days",
      dataIndex: "totalfirst",
      key: "totalfirst",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalfirst !== ""
              ? formatNumber(
                  parseFloat((row.totalfirst || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "11-15 Days",
      dataIndex: "totalsecond",
      key: "totalsecond",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalsecond !== ""
              ? formatNumber(
                  parseFloat((row.totalsecond || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "16-30 Days",
      dataIndex: "totalthird",
      key: "totalthird",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalthird !== ""
              ? formatNumber(
                  parseFloat((row.totalthird || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "31-45 Days",
      dataIndex: "totalfourth",
      key: "totalfourth",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalfourth !== ""
              ? formatNumber(
                  parseFloat((row.totalfourth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "46-60 Days",
      dataIndex: "totalfifth",
      key: "totalfifth",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalfifth !== ""
              ? formatNumber(
                  parseFloat((row.totalfifth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "61-75 Days",
      dataIndex: "totalsixth",
      key: "totalsixth",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalsixth !== ""
              ? formatNumber(
                  parseFloat((row.totalsixth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "76-90 Days",
      dataIndex: "totalseventh",
      key: "totalseventh",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totalseventh !== ""
              ? formatNumber(
                  parseFloat((row.totalseventh || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
    {
      title: "90 Days",
      dataIndex: "totaleighth",
      key: "totaleighth",
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            {row.totaleighth !== ""
              ? formatNumber(
                  parseFloat((row.totaleighth || "0").replace(/,/g, ""))
                )
              : "-"}
          </div>
        );
      },
    },
  ];


  return (
    <Fragment>
      <Table
        bordered
        ref={tableContainerRef}
        scroll={{ x: 900, y: 100 * 5 }}
        className="dashboard_tables payment_table"
        dataSource={invoiceTableData}
        loading={isLoading}
        rowKey="uniqueKey"
        columns={columns}
        rowClassName={(record) =>
          record.invoiceDate === "Total" ? "total-row" : ""
        }
        pagination={false}
        onScroll={handleTableOnScrollData}
        summary={() => {
          return (
            <Table.Summary.Row
              className="tbl_summary"
              style={{ position: "relative" }}
            >
              <Table.Summary.Cell
                colSpan={1}
                index={0}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                Total
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                {}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={2}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                {}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={3}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                {}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={4}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                {}
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={5}
                style={{
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  background: "#f5f5f5",
                  zIndex: 2,
                }}
              >
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(
                      finalInvoiceDataRef?.current.totalInvoiceamtTotal
                    )
                  : "0.00"}
              </Table.Summary.Cell>
              {isEmployee && (
                <Table.Summary.Cell>
                  {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                    ? formatNumber(finalInvoiceDataRef?.current.total)
                    : "0.00"}
                </Table.Summary.Cell>
              )}
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.totalUndamtTotal)
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(
                      finalInvoiceDataRef?.current.totalUnadjamtTotal
                    )
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(
                      finalInvoiceDataRef?.current.totalPendingAmount
                    )
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.totalcreditTotal)
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(
                      finalInvoiceDataRef?.current.totalCreditdaysTotal
                    )
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(
                      finalInvoiceDataRef?.current.totalOverDuedaysTotal
                    )
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(
                      finalInvoiceDataRef?.current.totalTodaytotTotal
                    )
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.firstTotal)
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.secondTotal)
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.thirdTotal)
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.fourthTotal)
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.fifthTotal)
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.sixthTotal)
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.seventhTotal)
                  : "0.00"}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                {Object.keys(finalInvoiceDataRef?.current)?.length > 0
                  ? formatNumber(finalInvoiceDataRef?.current.eighthTotal)
                  : "0.00"}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Fragment>
  );
};

export default InvoiceDetailsTbl;
