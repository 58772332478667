import React, { useEffect, useState } from "react";
import { Card, Input } from "antd";
import { NotificationManager } from "react-notifications";
import visitorManagementService from "../../services/VisitorManagement/visitorManagement-service";
import VisitorReportTable from "./Tables/visitorReportTable";
import moment from "moment";
import { exportFileImg, qrCodeImg } from "../Elite/imageAssets";

import QRCodeScanWithDetailsModal from "../../components/qrCodeScanWithDetails";
import TimeFormatAmPm from "../../utils/timeFormatAmPm";

const { Search } = Input;

const VisitorReport = () => {
  const isMobileResponsive = window.innerWidth >= 768 ? true : false;
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [visitorTableData, setVisitorTableData] = useState({});
  const [tableObjColumn, setTableObjColumn] = useState({
    sortColumn: "",
    orderBy: "",
    currentPage: 1, // Initialized as number
  });
  const [tableSearchData, setTableSearchData] = useState("");
  const [qrScanning, setQRScanning] = useState(false);

  useEffect(() => {
    fetchVisitorTableData();
  }, [tableObjColumn]);

  //// table column sorting and pagination code
  const handleTableSortColumn = (data) => {
    setTableObjColumn({
      sortColumn: data.sortColumn,
      orderBy: data.orderBy,
      currentPage: data.currentPage,
    });
  };
  //// table input search code
  const handleTblSearchChange = (e) => {
    setTableSearchData(e.target.value);
  };
  //// onSearch data code
  const handleOnSearchData = (value) => {
    setTableSearchData(value);
    fetchVisitorTableData();
  };

  /// Fetch Table Data API
  const fetchVisitorTableData = () => {
    setIsTableLoading(true);

    const inputData = {
      pageindex:
        tableObjColumn.currentPage !== "" ? tableObjColumn?.currentPage : 1,
      pagesize: 10,
      sortcolumn: tableObjColumn !== "" ? tableObjColumn?.sortColumn : "",
      orderBy: tableObjColumn.orderBy !== "" ? tableObjColumn?.orderBy : "desc",
      search: tableSearchData,
      isReport: true,
    };

    visitorManagementService
      .getVisitorManagementTableDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const finalData = response.data.responseData;
          setVisitorTableData(finalData || {});
        } else {
          NotificationManager.error(
            response.data.message || "Failed to fetch data."
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching visitor table data:", error);
        NotificationManager.error("An error occurred while fetching data.");
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  /// Export Data API
  const handleExportVisitorData = () => {
    setIsTableLoading(true);
    const currentDate = moment().format("YYYY-MM-DD");

    const inputData = {
      pageindex: 0,
      pagesize: 0,
      sortcolumn: tableObjColumn.sortColumn,
      orderBy: tableObjColumn.orderBy,
      search: tableSearchData,
      isReport: true,
    };

    visitorManagementService
      .exportVisitorListAPI(inputData)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Visitor_Report_${currentDate}.xlsx`);

        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);

        NotificationManager.success("Excel export successful!");
      })
      .catch((error) => {
        console.error("Error exporting visitor data:", error);
        NotificationManager.error("Excel export failed.");
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };
  const handleSubmitScanData = (visitorId) => {
    const inputData = {
      visitorId,
      scanTime: TimeFormatAmPm(new Date()),
    };

    visitorManagementService
      .UpdateVisitorInOutTimeAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (response.data.message === "Success") {
            fetchVisitorTableData();
            NotificationManager.success(response.data.responseData);
          }
          setQRScanning(false);
        } else {
          NotificationManager.error(response.data.responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching visitor table data:", error);
      })
      .finally(() => {
        setQRScanning(false);
      });
  };
  return (
    <div>
      <div className="app-content content visitorPass">
        <div className="content-wrapper">
          <div className="content-body">
            <h5 className="content-header-title d-inline-block">
              Visitor Report
            </h5>

            <div className="gate_pass_section">
              {isMobileResponsive && (
                <div className="row payment_tab">
                  <label className="analy_type">Visitor Data</label>
                </div>
              )}

              <div className="d-flex">
                <div className="tab_report_searchtxt pb_0">
                  <Search
                    placeholder="Search..."
                    className="dash_search"
                    allowClear
                    onSearch={() => handleOnSearchData()}
                    size="large"
                    value={tableSearchData}
                    onChange={handleTblSearchChange}
                  />
                </div>

                <div
                  className={
                    isMobileResponsive ? "export_lbl" : "export_lbl_Mobile"
                  }
                >
                  {isMobileResponsive ? (
                    <div>
                      <label onClick={handleExportVisitorData}>
                        Export
                        <img src={exportFileImg} alt="Export icon" />
                      </label>
                    </div>
                  ) : (
                    <div>
                      <img
                        src={exportFileImg}
                        alt="Export icon"
                        onClick={handleExportVisitorData}
                      />

                      {/* <img
                        src={qrCodeImg}
                        alt="QR icon"
                        onClick={() => setQRScanning(true)}
                      /> */}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {!isMobileResponsive && (
              <QRCodeScanWithDetailsModal
                visible={qrScanning}
                onSubmitData={handleSubmitScanData}
                onCancel={() => setQRScanning(false)}
              />
            )}

            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <VisitorReportTable
                    visitorTableData={visitorTableData}
                    visitorLoading={isTableLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorReport;
