import {
  SET_PAYMENTTABLEFILTER,
  SET_SELECTEDCUSTOMERDATA,
} from "./paymentTableFilterTypes";

export const setPaymentTableFilter = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PAYMENTTABLEFILTER,
      payload: data,
    });
  } catch (error) {}
};

export const setSelectedCustomerData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_SELECTEDCUSTOMERDATA,
      payload: data,
    });
  } catch (error) {}
};
