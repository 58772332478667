import React, { Fragment, useEffect, useState } from "react";
import { Drawer, Button, Select, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import EmployeeFilterService from "./../services/employee-filter-service";
import { useSelector, useDispatch } from "react-redux";
import {
  setGlobalFiltersSapCode,
  setGlobalFiltersIds,
} from "../actions/globalFilter/globalFilterAction";

const { Option } = Select;

const GlobalFilterDrawer = ({
  isGlobalFilter,
  showFilterDrawer,
  CloseFilterDrawer,
  isEmployee,
  applyFilter,
}) => {
  const dispatch = useDispatch();
  const loginDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const globalFilters = useSelector(
    (state) => state.GlobalFilters.globalFiltersIds
  );

  const [generalManagerData, setGeneralManagerData] = useState([]);
  const [regionalManagerData, setRegionalManagerData] = useState([]);
  const [areaManagerData, setAreaManagerData] = useState([]);
  const [groupNameData, setGroupNameData] = useState([]);
  const [companyNameData, setCompanyNameData] = useState([]);
  const [custGroupNameData, setCustGroupNameData] = useState([]);
  const [custOrganizationData, setCustOrganizationData] = useState([]);
  const [customerUsersData, setCustomerUsersData] = useState([]);
  const [filterIds, setFilterIds] = useState([]);
  const [sapCodesIds, setSapCodesIds] = useState([]);
  const [empFilter, setEmpFilter] = useState({
    generalManager: globalFilters?.generalManager || [],
    regionalManager: globalFilters?.regionalManager || [],
    areaManager: globalFilters?.areaManager || [],
    groupName: globalFilters?.groupName || [],
    custOrganization: globalFilters?.custOrganization || [],
  });

  const [custFilter, setCustFilter] = useState({
    groupName: globalFilters?.groupName?.map(Number) || [],
    custOrganization: globalFilters?.custOrganization?.map(Number) || [],
    selectedCustomerUser: globalFilters?.selectedCustomerUser || null,
  });
  const [selectedCompany, setSelectedCompany] = useState(
    companyDetails?.company_Code || ""
  );

  useEffect(() => {
    let CompanyCode = localStorage.getItem("Company_Code");
    if (selectedCompany !== CompanyCode) {
      setSelectedCompany(CompanyCode);
      setEmpFilter({
        generalManager: [],
        regionalManager: [],
        areaManager: [],
        groupName: [],
        custOrganization: [],
      });
    }
  }, [companyDetails]);

  useEffect(() => {
    if (isGlobalFilter) {
      if (isEmployee) {
        switch (loginDetails.designation) {
          case "Director":
            handleGetEmpGeneralManagerData();
            break;
          case "General Manager":
            handleGetRegionalManagerData("");
            break;
          case "Regional Manager":
            handleGetAreaManagerData("");
            break;

          default:
            console.log(loginDetails.designation, "User Role");
            break;
        }
        handleGetGroupNamesData();
        if (globalFilters && Object.keys(globalFilters)?.length > 0) {
          if (
            globalFilters?.regionalManager.length > 0 ||
            globalFilters?.areaManager.length > 0
          ) {
            handleGetRegionalManagerData(globalFilters?.generalManager);
            handleGetAreaManagerData(globalFilters?.regionalManager);
          }
        }

        handleGetCompanyNamesData(globalFilters?.groupName);
      } else {
        handleGetCustGroupNameData();
        handleGetCustomerUsersByOrganisationIdsData();

        if (globalFilters && Object.keys(globalFilters)?.length > 0) {
          handleGetCustOrganisationByGroupIdsData(globalFilters?.groupName);
        }
      }
    }
  }, [isGlobalFilter, globalFilters]);

  useEffect(() => {
    // handleGetCompanyNamesData(globalFilters?.groupName);
    handleGetGroupNamesData();
  }, [filterIds, sapCodesIds]);

  const handleEmpMultiSelectChange = (e, sapCode, name) => {
    let tempState = empFilter;
    tempState[name] = e;
    setEmpFilter((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    dispatch(setGlobalFiltersIds(tempState));
    if (e.length > 0) {
      switch (name) {
        case "generalManager":
          setRegionalManagerData([]);
          setAreaManagerData([]);
          tempState["regionalManager"] = [];
          tempState["areaManager"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState,
          }));
          handleGetRegionalManagerData(e);

          break;
        case "regionalManager":
          setAreaManagerData([]);
          tempState["areaManager"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState,
          }));
          handleGetAreaManagerData(e);
          break;
        case "areaManager":
          break;
        case "groupName":
          setCompanyNameData([]);
          tempState["custOrganization"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState,
          }));
          // handleGetCompanyNamesData(e);
          break;
        case "custOrganization":
          tempState["custOrganization"] = e;
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState,
          }));
          break;
        default:
          break;
      }
    } else {
      switch (name) {
        case "generalManager":
          setRegionalManagerData([]);
          setAreaManagerData([]);
          setGroupNameData([]);
          setCompanyNameData([]);
          tempState["regionalManager"] = [];
          tempState["areaManager"] = [];
          tempState["groupName"] = [];
          tempState["custOrganization"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState,
          }));
          break;
        case "regionalManager":
          setAreaManagerData([]);
          setGroupNameData([]);
          setCompanyNameData([]);
          tempState["regionalManager"] = [];
          tempState["areaManager"] = [];
          tempState["groupName"] = [];
          tempState["custOrganization"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState,
          }));
          break;
        case "areaManager":
          setGroupNameData([]);
          setCompanyNameData([]);
          tempState["areaManager"] = [];
          tempState["groupName"] = [];
          tempState["custOrganization"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState,
          }));
          break;
        case "groupName":
          setCompanyNameData([]);
          tempState["groupName"] = [];
          tempState["custOrganization"] = [];
          setEmpFilter((prevState) => ({
            ...prevState,
            ...tempState,
          }));
          break;
        case "custOrganization":
          // tempState["custOrganization"] = e;
          // setEmpFilter((prevState) => ({
          //   ...prevState,
          //   ...tempState,
          // }));
          break;
        default:
          break;
      }
    }
    if (name !== "groupName" && name !== "custOrganization") {
      setFilterIds((prevIds) => {
        const uniqueIds = new Set([
          ...prevIds,
          ...e.map((id) => id.toString()),
        ]);
        return Array.from(uniqueIds);
      });

      setSapCodesIds((prevIds) => {
        const uniqueSapIds = new Set([
          ...prevIds,
          ...sapCode.map((id) => id.toString()),
        ]);
        return Array.from(uniqueSapIds);
      });
      // handleGetGroupNamesData(sapCode);
      // handleGetCompanyNamesData(e);
    }
  };

  /// handle remove unselected value from drop down
  const handleDeSelectValues = (id, sapCode, name) => {
    let tempFilterIdsArray = filterIds;
    let tempSapCodeArray = sapCodesIds;
    let tempState = empFilter;

    if (filterIds.includes(id)) {
      let updatedArray = tempFilterIdsArray.filter((x) => x !== id);
      setFilterIds(updatedArray);
    }
    if (sapCodesIds.includes(sapCode)) {
      let updatedSapArray = tempSapCodeArray.filter((id) => id !== sapCode);
      setSapCodesIds(updatedSapArray);
    }

    if (name === "generalManager") {
      if (tempState["generalManager"].length > 0) {
        const generalManagerIds = generalManagerData;
        const regionalManagerIds = regionalManagerData;
        const areaManagerIds = areaManagerData;

        tempFilterIdsArray = tempFilterIdsArray.filter(
          (currentId) => currentId !== id
        );

        const regionalManagerIdSet = new Set(
          regionalManagerIds.map((item) => item.id)
        );
        const regionalManagerSapCode = new Set(
          regionalManagerIds.map((item) => item.employee_Sapcode)
        );

        tempFilterIdsArray = tempFilterIdsArray.filter(
          (id) => !regionalManagerIdSet.has(id)
        );
        tempSapCodeArray = tempSapCodeArray.filter(
          (employee_Sapcode) => !regionalManagerSapCode.has(employee_Sapcode)
        );
        const areaManagerIdSet = new Set(areaManagerIds.map((item) => item.id));
        const areaManagerSapCode = new Set(
          areaManagerIds.map((item) => item.employee_Sapcode)
        );

        tempFilterIdsArray = tempFilterIdsArray.filter(
          (id) => !areaManagerIdSet.has(id)
        );
        tempSapCodeArray = tempSapCodeArray.filter(
          (employee_Sapcode) => !areaManagerSapCode.has(employee_Sapcode)
        );
        tempFilterIdsArray = tempFilterIdsArray.filter(
          (currentCode) => currentCode !== id
        );
        tempSapCodeArray = tempSapCodeArray.filter(
          (currentCode) => currentCode !== sapCode
        );
        setSapCodesIds(tempSapCodeArray);
        setFilterIds(tempFilterIdsArray);
      }
    }

    if (name === "regionalManager") {
      if (tempState["regionalManager"].length > 0) {
        const regionalManagerIds = regionalManagerData;
        const areaManagerIds = areaManagerData;

        const regionalManagerIdSet = new Set(
          regionalManagerIds.map((item) => item.id)
        );

        tempFilterIdsArray = tempFilterIdsArray.filter(
          (currentId) => currentId !== id
        );

        tempSapCodeArray = tempSapCodeArray.filter(
          (currentCode) => currentCode !== sapCode
        );
        const areaManagerIdSet = new Set(areaManagerIds.map((item) => item.id));
        const areaManagerSapCode = new Set(
          areaManagerIds.map((item) => item.employee_Sapcode)
        );

        tempFilterIdsArray = tempFilterIdsArray.filter(
          (id) => !areaManagerIdSet.has(id)
        );
        tempSapCodeArray = tempSapCodeArray.filter(
          (employee_Sapcode) => !areaManagerSapCode.has(employee_Sapcode)
        );

        setFilterIds(tempFilterIdsArray);
        setSapCodesIds(tempSapCodeArray);
      }
    }

    if (name === "areaManager" && tempState["areaManager"].length > 0) {
      const areaManagerIds = areaManagerData;
      const areaManagerIdSet = new Set(areaManagerIds.map((item) => item.id));

      tempFilterIdsArray = tempFilterIdsArray.filter(
        (currentId) => currentId !== id
      );
      tempSapCodeArray = tempSapCodeArray.filter(
        (currentCode) => currentCode !== sapCode
      );
      setSapCodesIds(tempSapCodeArray);

      setFilterIds(tempFilterIdsArray);
    }
  };

  //// handle Cust multi select chages
  const handleCustMultiSelectChange = (e, name) => {
    if (name === "customerUsers") {
      if (e) {
        let findGroupOrgadata = customerUsersData.find((x) => x.id === e);
        let tempState = custFilter;
        tempState["groupName"] = [findGroupOrgadata.groupid];
        tempState["custOrganization"] = [findGroupOrgadata.organizationid];
        tempState["selectedCustomerUser"] = e;
        setCustFilter((prevState) => ({
          ...prevState,
          ...tempState,
        }));
        setCustGroupNameData([
          {
            id: findGroupOrgadata.groupid,
            name: findGroupOrgadata.groupname,
          },
        ]);
        setCustOrganizationData([
          {
            id: findGroupOrgadata.organizationid,
            name: findGroupOrgadata.organizationName,
            sapCode: findGroupOrgadata.organizationSapcode,
          },
        ]);
      }
    } else {
      let tempState = custFilter;
      tempState[name] = e;
      setCustFilter((prevState) => ({
        ...prevState,
        ...tempState,
      }));
      if (e.length > 0) {
        switch (name) {
          case "groupName":
            handleGetCustOrganisationByGroupIdsData(e);
            break;
          default:
            break;
        }
      } else {
        switch (name) {
          case "groupName":
            setCustOrganizationData([]);
            tempState["custOrganization"] = [];
            setCustFilter((prevState) => ({
              ...prevState,
              ...tempState,
            }));
            break;
          default:
            break;
        }
      }
    }
  };
  //// get Employee general Manager listing
  const handleGetEmpGeneralManagerData = () => {
    EmployeeFilterService.empGeneralManagerAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setGeneralManagerData(finalResponse);
          } else {
            setGeneralManagerData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// Handle get regional manager listing
  const handleGetRegionalManagerData = (ids) => {
    let inputData = {
      id: ids ? ids.toString() : "",
      email: loginDetails.email,
    };
    EmployeeFilterService.empRegionalManagerAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setRegionalManagerData(finalResponse);
          } else {
            setRegionalManagerData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// get Area manager listing
  const handleGetAreaManagerData = (ids) => {
    let inputData = {
      id: ids ? ids.toString() : "",
      email: loginDetails.email,
    };
    EmployeeFilterService.empAreaManagerAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setAreaManagerData(finalResponse);
          } else {
            setAreaManagerData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// get Group Name list
  const handleGetGroupNamesData = () => {
    let inputData = {
      id: "",
      email: loginDetails.email,
      sapCodes: sapCodesIds ? sapCodesIds.toString() : "",
    };
    EmployeeFilterService.empGroupNameAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setGroupNameData(finalResponse);
          } else {
            setGroupNameData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// get Company name listing
  const handleGetCompanyNamesData = (filterIds) => {
    let inputData = {
      id: filterIds ? filterIds.toString() : "",
      email: loginDetails.email,
    };
    EmployeeFilterService.empCompanyNameAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setCompanyNameData(finalResponse);
          } else {
            setCompanyNameData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// get Customer Group list
  const handleGetCustGroupNameData = () => {
    EmployeeFilterService.custGroupNameAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setCustGroupNameData(finalResponse);
          } else {
            setCustGroupNameData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// handle get customer organization using Group data
  const handleGetCustOrganisationByGroupIdsData = (ids) => {
    let inputData = {
      id: ids.toString(),
    };
    EmployeeFilterService.custOrganisationByGroupIdAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setCustOrganizationData(finalResponse);
          } else {
            setCustOrganizationData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// get Customer user list by Organization listing
  const handleGetCustomerUsersByOrganisationIdsData = () => {
    EmployeeFilterService.custUsersByOrganisationIdAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          if (finalResponse.length > 0) {
            setCustomerUsersData(finalResponse);
          } else {
            setCustomerUsersData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //// remove all selected filter code
  const handleRemoveAllFilter = () => {
    if (isEmployee) {
      setEmpFilter({
        generalManager: [],
        regionalManager: [],
        areaManager: [],
        groupName: [],
        custOrganization: [],
      });
      setRegionalManagerData([]);
      setAreaManagerData([]);
      setGroupNameData([]);
      setCompanyNameData([]);
      applyFilter({
        generalManager: [],
        regionalManager: [],
        areaManager: [],
        groupName: [],
        custOrganization: [],
      });
      setFilterIds([]);
      setSapCodesIds([]);
    } else {
      setCustFilter({
        groupName: [],
        custOrganization: [],
      });
      setCustOrganizationData([]);
      setCustomerUsersData([]);
      applyFilter({
        groupName: [],
        custOrganization: [],
        selectedCustomerUser: null,
      });
    }
    dispatch(setGlobalFiltersIds(null));
    dispatch(setGlobalFiltersSapCode(null));
    CloseFilterDrawer();
  };
  //// get Employee SAP code filter
  function getEmpSapCodesForFilters(filter, data, check) {
    if (check === "Emp") {
      return filter
        .map((id) => {
          let item = data.find((x) => x.id === id);
          return item ? item.employee_Sapcode : null;
        })
        .filter((sapcode) => sapcode !== null);
    } else {
      return filter
        .map((id) => {
          let item = data.find((x) => x.id === id);
          return item ? item.saP_Organization : null;
        })
        .filter((sapcode) => sapcode !== null);
    }
  }
  //// get Customer SAP code filter
  function getCustSapCodesForFilters(filter, data) {
    return filter
      .map((id) => {
        let item = data.find((x) => x.id === id);
        return item ? item.sapCode : null;
      })
      .filter((sapcode) => sapcode !== null);
  }
  //// handle apply filter code
  const handleApplyFilter = () => {
    let getSapCodeforFilters = {};
    if (isEmployee) {
      getSapCodeforFilters = {
        generalManager: getEmpSapCodesForFilters(
          empFilter.generalManager,
          generalManagerData,
          "Emp"
        ),
        regionalManager: getEmpSapCodesForFilters(
          empFilter.regionalManager,
          regionalManagerData,
          "Emp"
        ),
        areaManager: getEmpSapCodesForFilters(
          empFilter.areaManager,
          areaManagerData,
          "Emp"
        ),
        groupName: empFilter.groupName,
        custOrganization: getEmpSapCodesForFilters(
          empFilter.custOrganization,
          companyNameData,
          "organization"
        ),
      };
    } else {
      dispatch(setGlobalFiltersIds(custFilter));
      getSapCodeforFilters = {
        groupName: custFilter.groupName,
        custOrganization: getCustSapCodesForFilters(
          custFilter.custOrganization,
          custOrganizationData
        ),
      };
    }
    dispatch(setGlobalFiltersSapCode(getSapCodeforFilters));

    applyFilter(getSapCodeforFilters);
    CloseFilterDrawer();
  };

  return (
    <Fragment>
      <div>
        <Drawer
          title={isEmployee ? "Employee Filters" : "Customer Filters"}
          className="global_filter"
          placement="right"
          closable={false}
          onClose={CloseFilterDrawer}
          open={isGlobalFilter}
          drawerStyle={{ width: "100%", overflow: "hidden" }}
          bodyStyle={{ padding: 0 }}
          extra={
            <Space>
              <Button onClick={CloseFilterDrawer} className="fliter_close">
                <CloseOutlined />
              </Button>
            </Space>
          }
        >
          <div>
            <div className="content drawer-content">
              <div className="content-body">
                {isEmployee ? (
                  <div className="container drawer-select filter_dropdown">
                    <div>
                      <label className="filterLbl">
                        Select General Manager:
                      </label>
                      <Select
                        mode="multiple"
                        showArrow
                        showSearch
                        allowClear
                        placeholder="Please select"
                        onChange={(selectedIds) => {
                          const selectedSapcodes = selectedIds.map((id) => {
                            const selectedItem = generalManagerData.find(
                              (item) => item.id === id
                            );
                            return selectedItem.employee_Sapcode;
                          });

                          handleEmpMultiSelectChange(
                            selectedIds,
                            selectedSapcodes,
                            "generalManager"
                          );
                        }}
                        onDeselect={(selectedIds) => {
                          const selectedSapCode = generalManagerData.find(
                            (item) => item.id === selectedIds
                          ).employee_Sapcode;

                          handleDeSelectValues(
                            selectedIds,
                            selectedSapCode,
                            "generalManager"
                          );
                        }}
                        value={empFilter.generalManager}
                        optionFilterProp="children"
                        disabled={
                          loginDetails.designation === "General Manager" ||
                          loginDetails.designation === "Regional Manager" ||
                          loginDetails.designation === "Area Manager"
                            ? true
                            : false
                        }
                      >
                        {generalManagerData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">
                        Select Regional Manager:
                      </label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(selectedIds) => {
                          const selectedSapcodes = selectedIds.map((id) => {
                            const selectedItem = regionalManagerData.find(
                              (item) => item.id === id
                            );
                            return selectedItem.employee_Sapcode;
                          });

                          handleEmpMultiSelectChange(
                            selectedIds,
                            selectedSapcodes,
                            "regionalManager"
                          );
                        }}
                        onDeselect={(selectedIds) => {
                          const selectedSapCode = regionalManagerData.find(
                            (item) => item.id === selectedIds
                          ).employee_Sapcode;

                          handleDeSelectValues(
                            selectedIds,
                            selectedSapCode,
                            "regionalManager"
                          );
                        }}
                        value={empFilter.regionalManager}
                        optionFilterProp="children"
                        disabled={
                          loginDetails.designation === "Regional Manager" ||
                          loginDetails.designation === "Area Manager"
                            ? true
                            : false
                        }
                      >
                        {regionalManagerData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Area Manager:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(selectedIds) => {
                          const selectedSapcodes = selectedIds.map((id) => {
                            const selectedItem = areaManagerData.find(
                              (item) => item.id === id
                            );
                            return selectedItem.employee_Sapcode;
                          });

                          handleEmpMultiSelectChange(
                            selectedIds,
                            selectedSapcodes,
                            "areaManager"
                          );
                        }}
                        onDeselect={(selectedIds) => {
                          const selectedSapCode = areaManagerData.find(
                            (item) => item.id === selectedIds
                          ).employee_Sapcode;

                          handleDeSelectValues(
                            selectedIds,
                            selectedSapCode,
                            "areaManager"
                          );
                        }}
                        value={empFilter.areaManager}
                        optionFilterProp="children"
                        disabled={
                          loginDetails.designation === "Area Manager"
                            ? true
                            : false
                        }
                      >
                        {areaManagerData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Group:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleEmpMultiSelectChange(e, "", "groupName")
                        }
                        value={empFilter.groupName}
                        optionFilterProp="children"
                      >
                        {groupNameData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Company:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleEmpMultiSelectChange(e, "", "custOrganization")
                        }
                        value={empFilter.custOrganization}
                        optionFilterProp="children"
                      >
                        {companyNameData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                ) : (
                  <div className="container drawer-select filter_dropdown">
                    <div>
                      <label className="filterLbl">Select Group:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleCustMultiSelectChange(e, "groupName")
                        }
                        value={custFilter.groupName}
                        optionFilterProp="children"
                      >
                        {custGroupNameData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Organization:</label>
                      <Select
                        showSearch
                        mode="multiple"
                        showArrow
                        allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleCustMultiSelectChange(e, "custOrganization")
                        }
                        value={custFilter.custOrganization}
                        optionFilterProp="children"
                      >
                        {custOrganizationData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                    <div>
                      <label className="filterLbl">Select Contact:</label>
                      <Select
                        showSearch
                        // allowClear
                        placeholder="Please select"
                        onChange={(e) =>
                          handleCustMultiSelectChange(e, "customerUsers")
                        }
                        value={custFilter.selectedCustomerUser}
                        optionFilterProp="children"
                      >
                        {customerUsersData.map((item) => {
                          return (
                            <Option value={item.id} key={item.id}>
                              {item.fullName}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                )}

                <div className="filter-drawer-buttons container global_filter_btn">
                  <Button type="primary apply_flt" onClick={handleApplyFilter}>
                    Apply Filter
                  </Button>
                  <Button
                    type="primary reset_flt"
                    onClick={handleRemoveAllFilter}
                  >
                    Remove Filter
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </Fragment>
  );
};

export default GlobalFilterDrawer;
