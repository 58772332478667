import {
  SET_PAYMENTTABLEFILTER,
  SET_SELECTEDCUSTOMERDATA,
} from "../../actions/paymentTableFilter/paymentTableFilterTypes";

const initialState = {
  selectedRow: null,
  selectedCustomerData: null,
};

function paymentTableFilterReducer(selectedState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PAYMENTTABLEFILTER:
      return { ...selectedState, selectedRow: payload };
    case SET_SELECTEDCUSTOMERDATA:
      return { ...selectedState, selectedCustomerData: payload };

    default:
      return selectedState;
  }
}

export default paymentTableFilterReducer;
