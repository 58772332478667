export const PayloadForApplyFilterData = (applyFilterObj, companyDetails) => {
  return {
    general_Manager:
      Object.keys(applyFilterObj).length > 0 && applyFilterObj.generalManager
        ? applyFilterObj.generalManager.join(",")
        : "",
    regional_Manager:
      Object.keys(applyFilterObj).length > 0 && applyFilterObj.regionalManager
        ? applyFilterObj.regionalManager.join(",")
        : "",
    area_Manager:
      Object.keys(applyFilterObj).length > 0 && applyFilterObj.areaManager
      ? applyFilterObj.areaManager.join(",")
        : "",
    company_Code: localStorage.getItem("Company_Code"),
    groupIds:
      Object.keys(applyFilterObj).length > 0 && applyFilterObj.groupName
        ? applyFilterObj.groupName.join(",")
        : "",
    customer_OrganizationIds:
      Object.keys(applyFilterObj).length > 0 && applyFilterObj.custOrganization
        ? applyFilterObj.custOrganization.join(",")
        : "",
  };
};
