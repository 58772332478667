import React, { Fragment, useEffect, useState } from "react";
import { Card, Button, Select, DatePicker, TimePicker, Input } from "antd";
import { exportFileImg } from "../Elite/imageAssets";
import CreateNewVisitorTable from "./Tables/createNewVisitorTable";
import visitorManagementService from "../../services/VisitorManagement/visitorManagement-service";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import DeleteConfirmationModal from "../../components/deleteConfirmationModal";

const { Option } = Select;
const { TextArea, Search } = Input;

const CreateNewVisitor = () => {
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [visitorTableData, setVisitorTableData] = useState({});
  const [objCreateVisitor, setObjCreateVisitor] = useState({
    selectedCompany: null,
    selectedBranch: null,
    nameInput: "",
    contactInput: "",
    emailInput: "",
    companyNameInput: "",
    noOfVisitorInput: "",
    selectedLunch: null,
    purposeOfVisit: "",
    selectedVisitorType: "",
    selectedVisitorName: "",
    belongings: "",
    selectedDateTime: "",
    inTime: null,
    outTime: null,
    addressInput: "",
  });
  const [errors, setErrors] = useState({
    selectedCompanyError: "",
    selectedBranchError: "",
    nameInputError: "",
    emailError: "",
    contactInputError: "",
    companyNameInputError: "",
    noOfVisitorInputError: "",
    selectedLunchError: "",
    purposeOfVisitError: "",
    selectedVisitorTypeError: "",
    belongingsError: "",
    selectedDateTimeError: "",
  });

  const [companyListData, setCompanyListData] = useState([]);
  const [branchListData, setBranchListData] = useState([]);
  const [visitorTypeListData, setvisitorTypeListData] = useState([]);

  const [tableSearchData, setTableSearchData] = useState("");
  const [isUpdateDeletId, setIsUpdateDeletId] = useState("");
  const [isDeletMdlVisible, setIsDeletMdlVisible] = useState(false);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [tableObjColumn, setTableObjColumn] = useState({
    sortColumn: "",
    orderBy: "",
    currentPage: "",
  });

  useEffect(() => {
    handleGetCompanyListData();
    handleGetBranchListData();
    handleGetVisitorTypeListData();
  }, []);

  useEffect(() => {
    handleGetVisitorTableData();
  }, [tableObjColumn, tableSearchData]);

  //// table sorting column
  const handleTableSortColumn = (data) => {
    setTableObjColumn({
      sortColumn: data.sortColumn,
      orderBy: data.orderBy,
      currentPage: data.currentPage,
    });
  };

  ///dropdown change code
  const handleDropdownOnChange = (value, name) => {
    setObjCreateVisitor((prev) => ({ ...prev, [name]: value }));

    if (name === "selectedVisitorType") {
      let findVisitName = visitorTypeListData?.find(
        (x) => x.id === value
      ).visitType;
      setObjCreateVisitor((prev) => ({
        ...prev,
        ["selectedVisitorName"]: findVisitName,
      }));
    }
    setErrors((prev) => ({ ...prev, [`${name}Error`]: "" }));
  };
  /// handle Input Onchange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    if (name === "emailInput") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      newErrors.emailError = emailRegex.test(value)
        ? ""
        : "Please enter a valid email!";
    }

    if (name === "contactInput") {
      const contactRegex = /^[0-9]{10}$/;
      newErrors.contactInputError = contactRegex.test(value)
        ? ""
        : "Contact number must be 10 digits!";
    }

    if (name === "noOfVisitorInput") {
      newErrors.noOfVisitorInputError = "";
    }

    if (name === "belongings") {
      newErrors.belongingsError = "";
    }
    setObjCreateVisitor((prev) => ({ ...prev, [name]: value }));
    setErrors(newErrors);
  };

  /// Time picker onchange code
  const handleTimeChange = (time, field) => {
    setObjCreateVisitor((prev) => ({ ...prev, [field]: time }));
  };
  /// handle Date picker onchange
  const handleDateChange = (value, dateString) => {
    setObjCreateVisitor((prev) => ({ ...prev, selectedDateTime: value }));
    setErrors((prev) => ({ ...prev, selectedDateTimeError: "" }));
  };

  /// Reset Form
  const handleResetFormData = () => {
    setObjCreateVisitor({
      selectedCompany: null,
      selectedBranch: null,
      nameInput: "",
      contactInput: "",
      emailInput: "",
      companyNameInput: "",
      noOfVisitorInput: "",
      selectedLunch: null,
      purposeOfVisit: "",
      selectedVisitorType: "",
      selectedVisitorName: "",
      belongings: "",
      selectedDateTime: "",
      inTime: null,
      outTime: null,
      addressInput: "",
    });
    setErrors({
      selectedCompanyError: "",
      selectedBranchError: "",
      nameInputError: "",
      emailError: "",
      contactInputError: "",
      companyNameInputError: "",
      noOfVisitorInputError: "",
      selectedLunchError: "",
      purposeOfVisitError: "",
      selectedVisitorTypeError: "",
      belongingsError: "",
      selectedDateTimeError: "",
    });
  };

  const handleFormValidation = () => {
    let newErrors = { ...errors };
    let isValid = true;

    if (!objCreateVisitor.selectedCompany) {
      newErrors.selectedCompanyError = "Company Name is required!";
      isValid = false;
    } else {
      newErrors.selectedCompanyError = "";
    }

    if (!objCreateVisitor.selectedBranch) {
      newErrors.selectedBranchError = "Branch Name is required!";
      isValid = false;
    } else {
      newErrors.selectedBranchError = "";
    }

    if (!objCreateVisitor.nameInput.trim()) {
      newErrors.nameInputError = "Name is required!";
      isValid = false;
    } else {
      newErrors.nameInputError = "";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      !objCreateVisitor.emailInput ||
      !emailRegex.test(objCreateVisitor.emailInput)
    ) {
      newErrors.emailError = "Please enter a valid email!";
      isValid = false;
    } else {
      newErrors.emailError = "";
    }

    if (!objCreateVisitor.contactInput.trim()) {
      newErrors.contactInputError = "Contact Number is required!";
      isValid = false;
    } else if (!/^\d{10}$/.test(objCreateVisitor.contactInput)) {
      newErrors.contactInputError = "Contact Number must be 10 digits!";
      isValid = false;
    } else {
      newErrors.contactInputError = "";
    }

    if (!objCreateVisitor.companyNameInput.trim()) {
      newErrors.companyNameInputError = "Company Name is required!";
      isValid = false;
    } else {
      newErrors.companyNameInputError = "";
    }

    if (!objCreateVisitor.noOfVisitorInput.trim()) {
      newErrors.noOfVisitorInputError = "Number of Visitors is required!";
      isValid = false;
    } else if (isNaN(Number(objCreateVisitor.noOfVisitorInput))) {
      newErrors.noOfVisitorInputError = "Number of Visitors must be a number!";
      isValid = false;
    } else {
      newErrors.noOfVisitorInputError = "";
    }

    if (!objCreateVisitor.selectedLunch) {
      newErrors.selectedLunchError = "Lunch selection is required!";
      isValid = false;
    } else {
      newErrors.selectedLunchError = "";
    }

    if (!objCreateVisitor.purposeOfVisit.trim()) {
      newErrors.purposeOfVisitError = "Purpose of Visit is required!";
      isValid = false;
    } else {
      newErrors.purposeOfVisitError = "";
    }

    if (!objCreateVisitor.selectedVisitorType) {
      newErrors.selectedVisitorTypeError = "Visitor Type is required!";
      isValid = false;
    } else {
      newErrors.selectedVisitorTypeError = "";
    }

    if (!objCreateVisitor.belongings.trim()) {
      newErrors.belongingsError = "Belongings field is required!";
      isValid = false;
    } else {
      newErrors.belongingsError = "";
    }

    if (!objCreateVisitor.selectedDateTime) {
      newErrors.selectedDateTimeError = "Date is required!";
      isValid = false;
    } else {
      newErrors.selectedDateTimeError = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSaveFormData = () => {
    if (handleFormValidation()) {
      setIsButtonLoading(true);
      let inputData = {
        ...(isUpdateDeletId && { visitorsId: isUpdateDeletId }),
        companyId: objCreateVisitor.selectedCompany.toString(),
        branchId: objCreateVisitor.selectedBranch.toString(),
        visitorsName: objCreateVisitor.nameInput,
        primaryEmail: objCreateVisitor.emailInput,
        contact: objCreateVisitor.contactInput,
        companyName: objCreateVisitor.companyNameInput,
        belongings: objCreateVisitor.belongings,
        date: objCreateVisitor.selectedDateTime,
        numberofVisitor: objCreateVisitor.noOfVisitorInput,
        purposeOfVisit: objCreateVisitor.purposeOfVisit,
        lunch: objCreateVisitor.selectedLunch,
        visitTypeId: objCreateVisitor.selectedVisitorType.toString(),
        visitType: objCreateVisitor.selectedVisitorName,
        address: objCreateVisitor.addressInput,
        inTime: moment(objCreateVisitor.inTime).format("h:mm A"),
        outTime: moment(objCreateVisitor.outTime).format("h:mm A"),
      };
      visitorManagementService
        .postCreateUpdateVisitorsAPI(inputData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            let finaldata = response.data.responseData;
            if (finaldata !== "") {
              NotificationManager.success(finaldata);
              handleGetVisitorTableData();
              handleResetFormData();
            } else {
              NotificationManager.error(response.data.message);
            }
            setIsUpdateDeletId("");
          }
        })
        .catch((error) => {
          console.log(error, "Error");
        })
        .finally(() => {
          setIsButtonLoading(false);
        });
    }
  };

  const handleTblSearchChange = (e) => {
    setTableSearchData(e.target.value);
  };
  const handleOnSearchData = (value) => {
    setTableSearchData(value);
    handleGetVisitorTableData();
  };
  const handleDeleteData = (id) => {
    setIsDeletMdlVisible(true);
    setIsUpdateDeletId(id);
  };
  /// handle close delete modal
  const handleCloseDeleteMdl = () => {
    setIsDeletMdlVisible(false);
    setIsUpdateDeletId("");
  };

  /// Get company list API
  const handleGetCompanyListData = () => {
    visitorManagementService
      .getCompanyListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setCompanyListData(finaldata);
          } else {
            setCompanyListData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// Get branch list API
  const handleGetBranchListData = () => {
    visitorManagementService
      .getBranchListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setBranchListData(finaldata);
          } else {
            setBranchListData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// Get Visitor Type list API
  const handleGetVisitorTypeListData = () => {
    visitorManagementService
      .getVisitorTypeListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setvisitorTypeListData(finaldata);
          } else {
            setvisitorTypeListData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// Get Visitor Table list API
  const handleGetVisitorTableData = () => {
    setIsTableLoading(true);
    let inputData = {
      pageindex:
        tableObjColumn.currentPage !== "" ? tableObjColumn?.currentPage : 1,
      pagesize: 10,
      sortcolumn: tableObjColumn !== "" ? tableObjColumn?.sortColumn : "",
      orderBy: tableObjColumn.orderBy !== "" ? tableObjColumn?.orderBy : "desc",
      search: tableSearchData,
      isReport: false,
    };
    visitorManagementService
      .getVisitorManagementTableDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (Object.keys(finaldata).length > 0) {
            setVisitorTableData(finaldata);
          } else {
            setVisitorTableData({});
          }
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsTableLoading(false);
      })
      .catch((error) => {
        setIsTableLoading(false);
        console.log(error, "Error");
      });
  };
  /// Delete API
  const handleDeleteAPICalled = () => {
    visitorManagementService
      .deleteVisitorAPI(isUpdateDeletId)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata === "Deleted Successfully") {
            NotificationManager.success("Record Deleted Successfully!");
            handleGetVisitorTableData();
            setIsDeletMdlVisible(false);
            setIsUpdateDeletId("");
          } else {
            NotificationManager.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  ///Export data API
  const handleExportVisitorData = () => {
    setIsTableLoading(true);
    const currentDate = moment().format("YYYY-MM-DD");

    const inputData = {
      pageindex: 0,
      pagesize: 0,
      sortcolumn: "",
      orderBy: "",
      search: "",
      isReport: false,
    };

    visitorManagementService
      .exportVisitorListAPI(inputData)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Visitor_Report_${currentDate}.xlsx`);

        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);

        NotificationManager.success("Excel Export Successful!");
      })
      .catch((error) => {
        NotificationManager.error("Excel export failed.");
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  /// Print visitor card API
  const handlePrintVisitorcard = (id) => {
    setIsTableLoading(true);
    visitorManagementService
      .printVisitorCardAPI(id)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL, "_blank");
      })
      .catch((error) => {
        NotificationManager.error("Print failed.");
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  //// handle get record for update data
  const handleEditData = (editData) => {
    const tempDateTime = moment(editData.date);
    setObjCreateVisitor({
      selectedCompany: +editData.companyId,
      selectedBranch: +editData.branchId,
      nameInput: editData.visitorsName,
      contactInput: editData.contact,
      emailInput: editData.primaryEmail,
      companyNameInput: editData.companyName,
      noOfVisitorInput: editData.numberofVisitor,
      selectedLunch: editData.lunch,
      purposeOfVisit: editData.purposeOfVisit,
      selectedVisitorType: +editData.visitTypeId,
      belongings: editData.belongings,
      selectedDateTime: tempDateTime,
      inTime: editData.inTime,
      outTime: editData.outTime,
      addressInput: editData.address,
    });
    setIsUpdateDeletId(editData.visitorsId);
    setErrors({
      selectedCompanyError: "",
      selectedBranchError: "",
      nameInputError: "",
      emailError: "",
      contactInputError: "",
      companyNameInputError: "",
      noOfVisitorInputError: "",
      selectedLunchError: "",
      purposeOfVisitError: "",
      selectedVisitorTypeError: "",
      belongingsError: "",
      selectedDateTimeError: "",
    });
  };

  return (
    <Fragment>
      <div className="app-content content visitorPass">
        <div className="content-wrapper">
          <div className="content-body">
            <h5 className="content-header-title d-inline-block">
              Create New Visitor
            </h5>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-12">
                <div className="card ">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="ft-16 fnt_w_bold">Detail One</h4>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Select
                            allowClear
                            placeholder="Select Company"
                            onChange={(e) =>
                              handleDropdownOnChange(e, "selectedCompany")
                            }
                            value={objCreateVisitor.selectedCompany}
                            optionFilterProp="children"
                          >
                            {companyListData?.map((item) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.company}
                                </Option>
                              );
                            })}
                          </Select>
                          {errors.selectedCompanyError && (
                            <p className="inputError">
                              {errors.selectedCompanyError}
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Select
                            allowClear
                            placeholder="Select Branch"
                            onChange={(e) =>
                              handleDropdownOnChange(e, "selectedBranch")
                            }
                            value={objCreateVisitor.selectedBranch}
                            optionFilterProp="children"
                          >
                            {branchListData?.map((item) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.branch_Name}
                                </Option>
                              );
                            })}
                          </Select>
                          {errors.selectedBranchError && (
                            <p className="inputError">
                              {errors.selectedBranchError}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            name="nameInput"
                            placeholder="Name"
                            className="brdr_20"
                            value={objCreateVisitor.nameInput}
                            onChange={handleInputChange}
                          />
                          {errors.nameInputError && (
                            <p className="inputError">
                              {errors.nameInputError}
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            name="contactInput"
                            placeholder="Contact"
                            className="brdr_20"
                            value={objCreateVisitor.contactInput}
                            onChange={handleInputChange}
                          />
                          {errors.contactInputError && (
                            <p className="inputError">
                              {errors.contactInputError}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            name="emailInput"
                            placeholder="Email"
                            className="brdr_20"
                            value={objCreateVisitor.emailInput}
                            onChange={handleInputChange}
                          />
                          {errors.emailError && (
                            <p className="inputError">{errors.emailError}</p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            name="companyNameInput"
                            placeholder="Company Name"
                            className="brdr_20"
                            value={objCreateVisitor.companyNameInput}
                            onChange={handleInputChange}
                          />
                          {errors.companyNameInputError && (
                            <p className="inputError">
                              {errors.companyNameInputError}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-12 px-1">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body ">
                      <h4 className="ft-16 fnt_w_bold">Detail Two</h4>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            name="noOfVisitorInput"
                            placeholder="Number of Visitors"
                            className="brdr_20"
                            value={objCreateVisitor.noOfVisitorInput}
                            onChange={handleInputChange}
                          />
                          {errors.noOfVisitorInputError && (
                            <p className="inputError">
                              {errors.noOfVisitorInputError}
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Select
                            placeholder="Lunch"
                            optionFilterProp="children"
                            value={objCreateVisitor.selectedLunch}
                            onChange={(e) =>
                              handleDropdownOnChange(e, "selectedLunch")
                            }
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                          {errors.selectedLunchError && (
                            <p className="inputError">
                              {errors.selectedLunchError}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            name="purposeOfVisit"
                            placeholder="Purpose of Visit"
                            className="brdr_20"
                            value={objCreateVisitor.purposeOfVisit}
                            onChange={handleInputChange}
                          />
                          {errors.purposeOfVisitError && (
                            <p className="inputError">
                              {errors.purposeOfVisitError}
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Select
                            allowClear
                            placeholder="Visit Type"
                            optionFilterProp="children"
                            value={objCreateVisitor.selectedVisitorType}
                            onChange={(e) =>
                              handleDropdownOnChange(e, "selectedVisitorType")
                            }
                          >
                            {visitorTypeListData?.map((item) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.visitType}
                                </Option>
                              );
                            })}
                          </Select>
                          {errors.selectedVisitorTypeError && (
                            <p className="inputError">
                              {errors.selectedVisitorTypeError}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <div className="row pt-3">
                        <div className="col-12 px-1">
                          <Input
                            value={addressInput}
                            placeholder="User Inputs"
                            className="brdr_20"
                            onChange={(e) => setAddressInput(e.target.value)}
                          />
                        </div>
                      </div> */}
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <Input
                            name="belongings"
                            placeholder="Belongings"
                            className="brdr_20"
                            value={objCreateVisitor.belongings}
                            onChange={handleInputChange}
                          />
                          {errors.belongingsError && (
                            <p className="inputError">
                              {errors.belongingsError}
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <DatePicker
                            showTime
                            showNow={false}
                            showSecond={false}
                            placeholder="Select Date/Time"
                            value={objCreateVisitor.selectedDateTime}
                            onChange={handleDateChange}
                          />
                          {errors.selectedDateTimeError && (
                            <p className="inputError">
                              {errors.selectedDateTimeError}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body" style={{ minHeight: "184px" }}>
                      <div className="row pt-3">
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <TimePicker
                            placeholder="In Time"
                            format="h:mm A"
                            showNow={true}
                            value={
                              objCreateVisitor.inTime
                                ? moment(objCreateVisitor.inTime, "h:mm A")
                                : null
                            }
                            onChange={(time) =>
                              handleTimeChange(time, "inTime")
                            }
                            use12Hours
                          />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1">
                          <TimePicker
                            placeholder="Out Time"
                            format="h:mm A"
                            showNow={true}
                            value={
                              objCreateVisitor.outTime
                                ? moment(objCreateVisitor.outTime, "h:mm A")
                                : null
                            }
                            onChange={(time) =>
                              handleTimeChange(time, "outTime")
                            }
                            use12Hours
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-12 px-1">
                          <TextArea
                            name="addressInput"
                            placeholder="User Inputs"
                            autoSize={{ minRows: 3.5, maxRows: 3.5 }}
                            className="brdr_20"
                            value={objCreateVisitor.addressInput}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="reset_save_btn" style={{ paddingTop: "0" }}>
              <Button className="reset_btn" onClick={handleResetFormData}>
                Reset
              </Button>
              <Button
                disabled={isButtonLoading}
                loading={isButtonLoading}
                className="save_btn"
                onClick={handleSaveFormData}
              >
                Save
              </Button>
            </div>
            <div className="vertical_line" />
            <div className="gate_pass_section">
              <div className="row payment_tab">
                <label className="analy_type">Visitor Data</label>
              </div>
              <div className="d-flex">
                <div className="tab_searchtxt pb_0">
                  <Search
                    placeholder="Search..."
                    className="dash_search"
                    allowClear
                    onSearch={handleOnSearchData}
                    size="large"
                    value={tableSearchData}
                    onChange={handleTblSearchChange}
                  />
                </div>
                <div className="export_lbl">
                  <label onClick={handleExportVisitorData}>
                    Export
                    <img src={exportFileImg} alt="img" />
                  </label>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <CreateNewVisitorTable
                    visitorTableData={visitorTableData}
                    visitorLoading={isTableLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                    handleDeleteData={handleDeleteData}
                    handleEditData={handleEditData}
                    handlePrintVisitorcard={handlePrintVisitorcard}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        visible={isDeletMdlVisible}
        onDelete={handleDeleteAPICalled}
        onCancel={handleCloseDeleteMdl}
      />
    </Fragment>
  );
};

export default CreateNewVisitor;
