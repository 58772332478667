import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import KnackLogo from "../../assets/images/knackLogo.png";
import LoginService from "../../services/login-service";
import { NotificationManager } from "react-notifications";
import { setLoginDetails } from "../../actions/login/LoginAction";
import "./login.css";
import useEncryptData from "../../utils/EncryptData";
import Cookies from "js-cookie";

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const EncryptData = useEncryptData();

  const [activeTab, setActiveTab] = useState("customer");
  const [isRememberMe, setIsRememberMe] = useState(false);

  useEffect(() => {
    const rememberedUsername = Cookies.get("rememberedUsername");
    const rememberedPassword = Cookies.get("rememberedPassword");
    if (rememberedUsername) {
      form.setFieldsValue({
        email: rememberedUsername,
        password: rememberedPassword,
        remember: true,
      });
    }
  }, [form]);

  //// handle Login API called
  const handleLoginSubmit = (values) => {
    if (values.remember) {
      Cookies.set("rememberedUsername", values.email, { expires: 7 }); // expires in 7 days
      Cookies.set("rememberedPassword", values.password, { expires: 7 }); // expires in 7 days
    } else {
      Cookies.remove("rememberedUsername");
    }
    localStorage.removeItem("Company_Code");
    let inputData = {
      email: values.email.trim(),
      password: values.password,
      isEmployee: activeTab === "customer" ? false : true,
    };
    LoginService.login(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          let EncryptToken = EncryptData(finalResponse.token);
          finalResponse.token = EncryptToken;
          localStorage.setItem("token", EncryptToken);
          localStorage.setItem("selectedAnalysisType", "C");
          dispatch(setLoginDetails(finalResponse));
          navigate("/galaxyHome");
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Login Error");
      });
  };

  return (
    <div className="app-content content login_pg">
      <div className="content-wrapper">
        <div className="content-body">
          <section className="flexbox-container">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <div className="col-md-4 col-10 box-shadow-2 p-0 login-block">
                <div className="card border-grey border-lighten-3 px-3 pt-30 pb-2 m-0">
                  <div className="kanck_logo text-center">
                    <img src={KnackLogo} alt="logo" className="w-25" />
                  </div>
                  <div className="mt-2 border-0 login_tabs">
                    <div className="card-title text-center ">
                      <ul className="nav nav-tabs border-0 p-3 justify-content-center">
                        <li className="nav-item col-md-6">
                          <button
                            className={`nav-link ${
                              activeTab === "customer" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("customer")}
                          >
                            Customer
                          </button>
                        </li>
                        <li className="nav-item col-md-6">
                          <button
                            className={`nav-link ${
                              activeTab === "employee" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("employee")}
                          >
                            Employee
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <Form
                      form={form}
                      className="mt-4 login_form"
                      onFinish={handleLoginSubmit}
                      autoComplete="off"
                      noValidate
                    >
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message:
                              activeTab === "customer"
                                ? "Please enter your Email"
                                : "Please enter your Email/HRMS Code",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          placeholder={
                            activeTab === "customer"
                              ? "Customer Email ID"
                              : "Employee Email ID/HRMS Code"
                          }
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          prefix={
                            <KeyOutlined className="site-form-item-icon" />
                          }
                          size="large"
                          placeholder="Enter Password"
                          autoComplete="new-password"
                        />
                      </Form.Item>
                      <div className="d-flex justify-content-between">
                        <Form.Item name="remember" valuePropName="checked">
                          <Checkbox
                            checked={isRememberMe}
                            onChange={(e) => setIsRememberMe(e.target.checked)}
                          >
                            Remember me
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <Form.Item className="form_button">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login_btn w-100"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Login;
