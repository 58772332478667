import React, { Fragment, useEffect, useState } from "react";
// import { editIcon, deleteIcon } from "../../Elite/imageAssets";
import { Table } from "antd";

const VisitorReportTable = ({
  visitorTableData,
  visitorLoading,
  OnhandleTableSortColumn,
}) => {
  // const navigate = useNavigate();
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    if (visitorTableData?.visitorsRecord?.length > 0) {
      setTotalRecords(visitorTableData.totalRecords);
      setPageNo(Number(visitorTableData.currentPage));
    } else {
      setTotalRecords(0);
      setPageNo(1);
    }
  }, [visitorTableData]);

  //// handle Table column sorting and pagination
  const handleTableAscDes = (pagination, filters, sorter) => {
    const sortOrder = sorter.order;
    const sortField = sorter.field;
    OnhandleTableSortColumn({
      currentPage: pagination.current,
      sortColumn: sortField,
      orderBy:
        sortOrder === "ascend"
          ? "asc"
          : sortOrder === "descend"
          ? "desc"
          : "asc",
    });
  };
  //// Date format function code
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const tableColumns = [
    {
      title: "Sr No",
      dataIndex: "",
      sorter: false,
      showSorterTooltip: false,
      width: 80,
      render: (_i, row, index) => {
        return (
          <div>
            <label>{index + 1}</label>
          </div>
        );
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      sorter: (a, b) => a.company - b.company,
      showSorterTooltip: false,
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.company}</label>
          </div>
        );
      },
    },
    {
      title: "Branch",
      dataIndex: "branch",
      sorter: (a, b) => a.branch - b.branch,
      showSorterTooltip: false,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.branch}</label>
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "visitorsName",
      sorter: (a, b) => a.visitorsName - b.visitorsName,
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.visitorsName}</label>
          </div>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: "contact",
      sorter: (a, b) => a.contact - b.contact,
      showSorterTooltip: false,
      width: 120,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.contact}</label>
          </div>
        );
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      sorter: (a, b) => a.companyName - b.companyName,
      showSorterTooltip: false,
      width: 200,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.companyName}</label>
          </div>
        );
      },
    },
    {
      title: "Belongings",
      dataIndex: "belongings",
      sorter: (a, b) => a.belongings - b.belongings,
      showSorterTooltip: false,
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.belongings}</label>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      showSorterTooltip: false,
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            <label>{formatDate(row.date)}</label>
          </div>
        );
      },
    },
    {
      title: "In Time",
      dataIndex: "inTime",
      sorter: (a, b) => a.inTime - b.inTime,
      showSorterTooltip: false,
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.inTime}</label>
          </div>
        );
      },
    },
    {
      title: "Out Time",
      dataIndex: "outTime",
      sorter: (a, b) => a.outTime - b.outTime,
      showSorterTooltip: false,
      width: 150,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.outTime}</label>
          </div>
        );
      },
    },
    {
      title: "Lunch",
      dataIndex: "lunch",
      sorter: (a, b) => a.lunch - b.lunch,
      showSorterTooltip: false,
      width: 100,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.lunch}</label>
          </div>
        );
      },
    },
    {
      title: "No of Visitor",
      dataIndex: "numberofVisitor",
      sorter: (a, b) => a.numberofVisitor - b.numberofVisitor,
      showSorterTooltip: false,
      width: 100,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.numberofVisitor}</label>
          </div>
        );
      },
    },
    {
      title: "Purpose",
      dataIndex: "purposeOfVisit",
      sorter: (a, b) => a.purposeOfVisit - b.purposeOfVisit,
      showSorterTooltip: false,
      width: 180,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.purposeOfVisit}</label>
          </div>
        );
      },
    },
    {
      title: "Visit Type",
      dataIndex: "visitType",
      sorter: (a, b) => a.visitType - b.visitType,
      showSorterTooltip: false,
      width: 180,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.visitType}</label>
          </div>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address - b.address,
      showSorterTooltip: false,
      width: 300,
      render: (_i, row) => {
        return (
          <div>
            <label>{row.address}</label>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Table
        bordered
        scroll={{ x: 1000 }}
        className="gatePassTbl payment_table"
        columns={tableColumns}
        dataSource={visitorTableData.visitorsRecord}
        loading={visitorLoading}
        onChange={handleTableAscDes}
        pagination={{
          total: totalRecords,
          pageNo: pageNo,
          pageSize: 10,
          responsive: true,
          showSizeChanger: false,
          current: pageNo,
          onChange: (pageNum) => setPageNo(pageNum),
        }}
      />
    </Fragment>
  );
};

export default VisitorReportTable;
