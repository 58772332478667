import React, { Fragment, useEffect, useState, useRef } from "react";
import { Input, Card, Spin } from "antd";
import CustomerBarChart from "./CustomerBarChart";
import { NotificationManager } from "react-notifications";
import CurrencyOutstandingTbl from "./CurrencyOutstandingTbl";
import paymentService from "../../../../services/Payment/payment-service";
import { useSelector, useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import {
  setPaymentTableFilter,
  setSelectedCustomerData,
} from "../../../../actions/paymentTableFilter/paymentTableFilterAction";
import { PayloadForApplyFilterData } from "../../../../components/PayloadForApplyFilterData";
const { Search } = Input;

const CustomerTab = ({
  selectAnalysisType,
  selectedDistributionIds,
  selectedCountryIds,
  selectedPriceGroupIds,
  selectedSalesOfficeIds,
  SelectedBarChartData,
  isEmployee,
  applyFilterObj,
}) => {
  const dispatch = useDispatch();
  const isFirstCheckSearchRender = useRef(true);

  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const selectedCustomerAgingData = useSelector(
    (state) => state.PaymentTableFilter.selectedRow
  );

  const selectedCustomerData = useSelector(
    (state) => state.PaymentTableFilter.selectedCustomerData
  );
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [isTblLoading, setIsTblLoading] = useState(false);
  const [customerBarChartData, setCustomerBarChartData] = useState([]);
  const [currencyWiseDataTbl, setCurrencyWiseDataTbl] = useState([]);
  const [totalPendingLCY, setTotalPendingLCY] = useState(0);
  const [chartSearchtext, setChartSearchtext] = useState("");
  const [selectedChartCurrencyData, setSelectedChartCurrencyData] =
    useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);

  useEffect(() => {
    const searchTime = setTimeout(() => {
      handleGetPaymentChartData();
      handleGetPaymentCurrencyTableData();
    }, 200);
    // if (
    //   // hasFilterDataExist(applyFilterObj) ||
    //   selectedDistributionIds.length > 0 ||
    //   selectedCountryIds.length > 0 ||
    //   selectedPriceGroupIds.length > 0 ||
    //   selectedSalesOfficeIds.length > 0
    // ) {
    //   if (isFirstCheckSearchRender.current) {
    //     setChartSearchtext("");
    //     setSelectedBarData([]);
    //     setSelectedChartCurrencyData("");
    //     dispatch(setPaymentTableFilter(""));
    //     dispatch(setSelectedCustomerData(""));

    //     isFirstCheckSearchRender.current = false;
    //   }
    // }
    if (selectedCustomerAgingData) {
      setChartSearchtext(selectedCustomerAgingData);
    }
    // else {
    //   setChartSearchtext("");
    // }

    return () => {
      clearTimeout(searchTime);
    };
  }, [
    selectedDistributionIds,
    selectedCountryIds,
    selectedPriceGroupIds,
    selectedSalesOfficeIds,
    selectAnalysisType,
    chartSearchtext,
    selectedChartCurrencyData,
    companyDetails,
    selectedCustomerAgingData,
    // applyFilterObj,
  ]);

  useEffect(() => {
    if (
      companyDetails ||
      companyDetails === undefined ||
      companyDetails === null
    ) {
      setSelectedBarData([]);
      setChartSearchtext("");
      setSelectedChartCurrencyData("");
      dispatch(setPaymentTableFilter(""));
      dispatch(setSelectedCustomerData(""));
    }
  }, [companyDetails]);

  // useEffect(() => {
  //   setChartSearchtext(selectedCustomerAgingData);
  // }, [selectedCustomerAgingData]);

  // useEffect(() => {
  //   if (selectedBarData.length > 0) {
  //     dispatch(setSelectedCustomerData(selectedBarData));
  //     SelectedBarChartData(selectedBarData);
  //     const tempCustomerIds = selectedBarData
  //       .map((item) => item.customerId)
  //       .join(",");
  //     setSelectedChartCurrencyData(tempCustomerIds);
  //   }
  // }, [selectedBarData]);

  ///// API calling function code start

  /// handle fetch payment chart data listing
  const handleGetPaymentChartData = () => {
    setIsCustomerLoading(true);
    let inputData = {
      distributionChannel: selectedDistributionIds
        ? selectedDistributionIds.join(",")
        : "",
      country: selectedCountryIds.join(","),
      salesOffice: selectedSalesOfficeIds
        ? selectedSalesOfficeIds.join(",")
        : "",
      priceGroup: selectedPriceGroupIds ? selectedPriceGroupIds.join(",") : "",
      type: selectAnalysisType,
      search: chartSearchtext ? chartSearchtext.trim() : "",
      pageindex: "1",
      pagesize: "12",
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };
    paymentService
      .getPaymentBarChartDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.customerWiseList?.length > 0) {
            let tempData = finaldata.customerWiseList;
            let updatedChartData = tempData.map((item) => ({
              ...item,
              totalPendingAmount: isEmployee
                ? Math.abs(item.totalPendingAmount_LCY)
                : Math.abs(item.totalPendingAmount),
            }));
            setCustomerBarChartData(updatedChartData);
          } else {
            setCustomerBarChartData([]);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      })
      .finally(() => setIsCustomerLoading(false));
  };
  /// handle fetch payment currency Table data listing
  const handleGetPaymentCurrencyTableData = () => {
    setIsTblLoading(true);
    let inputData = {
      distributionChannel: selectedDistributionIds
        ? selectedDistributionIds.join(",")
        : "",
      country: selectedCountryIds.join(","),
      salesOffice: selectedSalesOfficeIds
        ? selectedSalesOfficeIds.join(",")
        : "",
      priceGroup: selectedPriceGroupIds ? selectedPriceGroupIds.join(",") : "",
      type: selectAnalysisType,
      search: chartSearchtext
        ? chartSearchtext.trim()
        : selectedCustomerData?.length > 0
        ? selectedChartCurrencyData.trim()
        : "",
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };
    paymentService
      .getPaymentCurrencyWiseDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.totalPaymentListResponse?.length > 0) {
            setCurrencyWiseDataTbl(finaldata.totalPaymentListResponse);
            setTotalPendingLCY(finaldata.total);
          } else {
            setCurrencyWiseDataTbl([]);
            setTotalPendingLCY(0);
          }
        } else {
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        setIsTblLoading(false);
        console.log(error, "Error");
      })
      .finally(() => {
        setIsTblLoading(false);
      });
  };

  ///// API calling function code end
  const handleOnSearchData = (value) => {
    setChartSearchtext(value);
    if (value === "") {
      isFirstCheckSearchRender.current = true;
    } else {
      isFirstCheckSearchRender.current = false;
    }
    if(selectedCustomerAgingData){
      dispatch(setPaymentTableFilter(""));
    }
  };

  //// chart data search onchange
  const handleChartSearchOnchange = (e) => {
    setChartSearchtext(e.target.value);
    if (e.target.value === "") {
      isFirstCheckSearchRender.current = true;
    } else {
      isFirstCheckSearchRender.current = false;
    }
    if(selectedCustomerAgingData){
      dispatch(setPaymentTableFilter(""));
    }
  };

  //// handle bar selected data
  const handleBarSelectedData = (data) => {
    const customerIdToCheck = data.customerId;
    const updatedData = (prev) => {
      const exists = prev.some((item) => item.customerId === customerIdToCheck);
      return exists ? prev : [...prev, data];
    };
    setSelectedBarData((prev) => {
      const newData = updatedData(prev);
      dispatch(setSelectedCustomerData(newData));
      SelectedBarChartData(newData);

      const tempCustomerIds = newData.map((item) => item.customerId).join(",");
      setSelectedChartCurrencyData(tempCustomerIds);

      return newData;
    });
    isFirstCheckSearchRender.current = false;
  };
  //// handle remove selected customer data
  const handleRemoveSelectedData = (customerId) => {
    const updatedData = selectedBarData.filter(
      (x) => x.customerId !== customerId
    );
    setSelectedBarData(updatedData);
    dispatch(setSelectedCustomerData(updatedData));
    SelectedBarChartData(updatedData);
    if (updatedData.length === 0) {
      setSelectedChartCurrencyData("");
      dispatch(setPaymentTableFilter(""));
      dispatch(setSelectedCustomerData(""));
      isFirstCheckSearchRender.current = true;
    } else {
      const tempCustomerIds = updatedData
        .map((item) => item.customerId)
        .join(",");
      setSelectedChartCurrencyData(tempCustomerIds);
    }
  };

  //// handle filter data exist code
  function hasFilterDataExist(obj) {
    return Object.values(obj).some((array) => array.length > 0);
  }
  return (
    <Fragment>
      <div className="pay_customer_tab">
        <div className="row">
          <div className="col-md-6 mb-10">
            <Card>
              <div className="cust_card">
                <div className="payment_customer_section">
                  <label className="card_lbl">Customers</label>
                  <div className="pay_search_int">
                    <Search
                      placeholder="Search..."
                      allowClear
                      className="dash_search"
                      size="large"
                      value={chartSearchtext}
                      onSearch={handleOnSearchData}
                      onChange={handleChartSearchOnchange}
                    />
                  </div>
                </div>
                <Spin size="large" spinning={isCustomerLoading}>
                  <div className="tag-container mt-2">
                    {selectedBarData.map((item, i) => {
                      return (
                        <div className="chart_tag" key={i}>
                          <span className="tag-text">
                            {item.fullName.length > 8
                              ? item.fullName.substring(0, 8) + "..."
                              : item.fullName}
                          </span>
                          <CloseOutlined
                            className="close-btn"
                            onClick={() =>
                              handleRemoveSelectedData(item.customerId)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                  <CustomerBarChart
                    isEmployee={isEmployee}
                    BarChartData={customerBarChartData}
                    handleBarClick={handleBarSelectedData}
                  />
                </Spin>
              </div>
            </Card>
          </div>
          <div className="col-md-6">
            <Card>
              <div className="d-flex justify-content-between">
                <label className="card_lbl">Currency wise outstanding</label>
              </div>
              <div className="cust_out_tbl">
                <CurrencyOutstandingTbl
                  tableData={currencyWiseDataTbl}
                  totalLCY={totalPendingLCY}
                  isLoading={isTblLoading}
                  isEmployee={isEmployee}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerTab;
